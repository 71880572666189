<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import stepsMixin from '@/mixins/stepsMixin'
const parseKeys = require('../../parseKeys.json')
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
		FormAnalysisBasic: () => import('@/components/simulator/arce/ArceForms/AnalysisBasic'),
		FormBusinessRisk: () => import('@/components/simulator/arce/ArceForms/AnalysisBusinessRisk'),
		FormAnalysisFinancialRisk: () => import('@/components/simulator/arce/ArceForms/AnalysisFinancialRisk'),
		FormStandaloneRating: () => import('@/components/simulator/arce/ArceForms/StandaloneRating'),
		FormOverlays: () => import('@/components/simulator/arce/ArceForms/Overlays'),
		FormRating: () => import('@/components/simulator/arce/ArceForms/FormRating'),
		SumaryBankRelationship: () => import('@/components/simulator/arce/ArceForms/SumaryBankRelationship'),
		SumaryBankRelationshipGroup: () => import('@/components/simulator/arce/ArceForms/SumaryBankRelationshipGroup'),
		FormEvaluation: () => import('@/components/simulator/arce/ArceForms/FormEvaluation'),
		FormEvaluationGroup: () => import('@/components/simulator/arce/ArceForms/FormEvaluationGroup'),
		FormPolicies: () => import('@/components/simulator/arce/ArceForms/FormPolicies'),
		FormPoliciesGroup: () => import('@/components/simulator/arce/ArceForms/FormPoliciesGroup'),
	},

	data() {
		return {
			selected: null,
			selectedDetails: null,
			validationIntro: true,
			validationResult: false,
			proposedSancion: '',
			loaderProposed: false,

			json_fields: {
				'Campo': 'field',
				'Datos': 'data',
			},

			json_policies_fields: {
				'Tipologia': 'tipologia',
				'Último Autorizado': 'ultimoAutorizado',
				'Comprometido': 'comprometido',
				'No Comprometido': 'noComprometido',
				'Límite Actual': 'limiteActual',
				'Dispuesto': 'dispuesto',
				'Propuesto': 'propuesto',
			},

			json_meta: [
				[
					{
						'key': 'charset',
						'value': 'utf-8'
					}
				]
			],
		}
	},

	computed: {
		
		group() {
			if(this.arce) {
				return this.clienById(this.arce.groupId)
			}
			return null
		},

		


		...mapGetters({
			arce: 'arceDetails/arce',
			clienById: 'arceClients/getById',
			userName: 'auth/userName',
            arceRatingsSelected: 'arceRatings/selected'
		})
	},

	// watch: {
	// 	group: {
	// 		handler(nVal) {
	// 			this.selected = nVal.id
	// 		},
	// 		deep: true
	// 	}
	// },


	methods: {
		initWizard() {			
			this.initArceWizard({
				data: {
					start: true,
					client: {},
				},
				steps: [
					{
						sort: 1,
						key: 'start',
						status: 'current'
					},
				]
			})

			this.showArceWizard()
		},
	
		...mapMutations({
			showArceWizard: 'arceStatus/showArceWizard',
			initArceWizard: 'arceStatus/initArceWizard',
            arceRatingsSetRating: 'arceRatings/setRating'
		}),
		
	},

	created() {
        this.arceRatingsSetRating(this.$route.params.id)
		console.log(this.arceRatingsSelected)
		if(!this.arceRatingsSelected.wizard_completed) {
			this.initWizard();
		}
		// this.eventBusOn('downloadProgress', this.excelData)
		// this.eventBusOn('downloadPolicies', this.excelPoliciesData)
		// this.getArce(this.$route.params.id).then(() => {
		// 	this.selected = this.arce.groupId
		// 	this.selectedDetails = {...this.clienById(this.selected)}
		// 	this.showSteps('stepsArceDetails')
		// })
		// console.log(parseKeys)
	},
}
</script>
<template>
	<div class="d-flex flex-wrap px-1 py-1 s-single">
		
		<div class="col-12 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2" style="position: sticky; top: 98px; z-index: 101">
					<div class="s-panel">
						<div class="s-panel__header bg-light px-3 py-2">
							<div class="d-flex align-items-center">
								<div class="s-element__icon">
									<img src="@/assets/simulator/account_120.png" alt="">
								</div>
								<div>
									<p class="m-0">ARCE</p>
									<h3 class="title">
										Rating - {{arceRatingsSelected.nombre}} - <span data-v-step="ad1">{{arceRatingsSelected.folio}}</span>
									</h3>
								</div>
								<div class="ml-auto d-flex">
									<!-- <button class="s-btn s-btn--success mx-2" @click="excelData">
										Descargar Avance 
										<SvgIcon name="download" />
									</button>
									<button class="s-btn s-btn--success mx-2" @click="excelPoliciesData">
										Descargar Políticas 
										<SvgIcon name="download" />
									</button> -->
									<!-- <export-excel v-if="arce && group"
										
										:data="excelData()"
										:fields="json_fields"
										type="csv"
										:name="`ARCE_${userName}_${group.name}_${arce.folio}.xls`">
										
									</export-excel> -->
									<!-- <export-excel v-if="arce && group"
										class="s-btn s-btn--success mx-2"
										:data="excelPoliciesData()"
										:fields="json_policies_fields"
										type="csv"
										:name="`ARCE_Tabla_politicas_${userName}_${group.name}_${arce.folio}.xls`">
										Descargar Políticas 
										<SvgIcon name="download" />
									</export-excel>
									<button class="s-btn s-btn--outline">
										<SvgIcon name="add"/>
										Seguir
									</button> -->

								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel" data-v-step="ad2">
						<div class="s-panel__body">
							<ul class="s-header-steps" v-if="arceRatingsSelected">
								<li class="s-header-steps__item" :class="{
									's-header-steps__item--completed': (arceRatingsSelected.status == 'finished'),
									's-header-steps__item--completed': (arceRatingsSelected.status == 'sanction'),
									's-header-steps__item--current': (arceRatingsSelected.status == 'prepair'),
								}">
									<span>
										IN PREPARATION
									</span>
								</li>
								<li class="s-header-steps__item" :class="{
									's-header-steps__item--completed': (arceRatingsSelected.status == 'finished'),
									's-header-steps__item--completed': (arceRatingsSelected.status == 'finished'),
									's-header-steps__item--current': (arceRatingsSelected.status == 'sanction'),
								}">
									<span>
										EN SANCTION
									</span>
								</li>
								
								
								<li class="s-header-steps__item">
									<span>
										FINALIZED
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__body ">
							<div class="text-center" data-v-step="ad7" style="white-space: nowrap; overflow: auto">
								<div v-if="arceRatingsSelected" class="col-3 px-1 d-inline-block" >
									<div class="border p-3 s-arce__client s-arce__client--selected">
										<!-- <h5>{{group.name}}</h5> -->
										<H6 class="mb-0">{{ arceRatingsSelected.nombre }}</H6>
										<p><b>Parent Company</b></p>
										<div class="px-2">
											<p class="mb-1">Alpha code</p>
											<p class="mb-1">{{ arceRatingsSelected.alpha_code }}</p>
											<p class="mb-1">RIC CODE</p>
											<p class="m-0">{{arceRatingsSelected.ric_code}}</p>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

                <div class="col-12 px-2 py-2">
                    <div class="s-panel">
                        <div class="s-panel__body " >
							<div class="s-nav-tab nav nav-tabs">
								<button class="s-nav-tab__item active"   data-toggle="tab" href="#sectionAnalysis" role="tab" aria-controls="FormAnalysisBasic" aria-selected="true">
									Analysis
								</button>
								<button class="s-nav-tab__item "  data-toggle="tab" href="#sectionRating" role="tab" aria-controls="FormAnalysisBasic" aria-selected="true">
									Rating
								</button>
							</div>
							<div class="py-3">
								<div class="border p-3">
									<div class="d-flex justify-content-center">
										<div class="col-auto px-5">
											<p class="m-0">Name</p>
											<div class="d-flex align-items-center">
												<div class="s-element__icon s-element__icon--24">
													<img src="@/assets/simulator/account_120.png" alt="">
												</div>
												<div>
													<p class="m-0" >
														{{arceRatingsSelected.nombre}}
													</p>
												</div>
											</div>
										</div>
										<div class="col-auto px-5">
											<p class="m-0">Folio</p>
											<div class="d-flex align-items-center">
												<div class="s-element__icon s-element__icon--blue s-element__icon--24">
													<SvgIcon name="trailhead"/>
												</div>
												<div>
													<p class="m-0" >
														{{arceRatingsSelected.folio}}
													</p>
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
                            <div class="tab-content">
								<div id="sectionAnalysis" class="tab-pane fade show active">
									<div class="nav nav-tabs border" style="background: #EFEFEF">
										<button class="nav-item nav-link active"  data-toggle="tab"  href="#FormAnalysisBasic" role="tab" aria-controls="FormAnalysisBasic" aria-selected="true">
											Basic Data
										</button>
										<button class="nav-item nav-link" data-v-step="ad12"  data-toggle="tab"  href="#FormBusinessRisk" role="tab" aria-controls="FormBusinessRisk" aria-selected="true">
											Business Risk
										</button>
										<button  class="nav-item nav-link"   data-toggle="tab" href="#FormAnalysisFinancialRisk" role="tab" aria-controls="FormAnalysisIndustry" aria-selected="true">
											Financial Risk
										</button>
									</div>

                                    <div class="tab-content">
										<div id="FormAnalysisBasic" class="tab-pane fade show active" >
											<div>
												<FormAnalysisBasic/>
											</div>
										</div>
										<div id="FormBusinessRisk" class="tab-pane">
											<div>
												<FormBusinessRisk  />
											</div>
											
										</div>
										
										<div id="FormAnalysisFinancialRisk" class="tab-pane fade">
											<div>
												<FormAnalysisFinancialRisk/>
											</div>
										</div>
									</div>
                                </div>
								<div id="sectionRating" class="tab-pane">
									<div class="nav nav-tabs border" style="background: #EFEFEF">
										<button class="nav-item nav-link active"  data-toggle="tab"  href="#FormStandaloneRating" role="tab" aria-controls="FormAnalysisBasic" aria-selected="true">
											Standalone Rating
										</button>
										<button class="nav-item nav-link" data-v-step="ad12"  data-toggle="tab"  href="#FormOverlays" role="tab" aria-controls="FormBusinessRisk" aria-selected="true">
											Overlays
										</button>
										<button  class="nav-item nav-link"   data-toggle="tab" href="#FormFinalRating" role="tab" aria-controls="FormAnalysisIndustry" aria-selected="true">
											Final Rating
										</button>
										<button  class="nav-item nav-link"   data-toggle="tab" href="#FormStudies" role="tab" aria-controls="FormAnalysisIndustry" aria-selected="true">
											Studies
										</button>
									</div>

                                    <div class="tab-content">
										<div id="FormStandaloneRating" class="tab-pane fade show active" >
											<FormStandaloneRating></FormStandaloneRating>
										</div>
										<div id="FormOverlays" class="tab-pane" >
											<FormOverlays></FormOverlays>
										</div>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

			
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header bg-light p-2">
							<h3 class="title" v-if="group">
								Trazabilidad
							</h3>
						</div>
						<div class="s-panel__body ">
							<div class="s-traz mb-1">
								<div class="s-traz__check">
									<svg-icon name="check" />
								</div>
								<div>
									<h6 class="m-0">Preparando Análisis</h6>
									<div>
										<div class="d-flex mb-1">
											<span class="s-traz__user mr-2">
												<img src="@/assets/simulator/user_120.png" alt="">
											</span>
											<p class="m-0">{{userName}}</p>
										</div>
										<p class="m-0 small text-muted">
											Etapa: En Preparación | Estado: Preparando Análisis | Código Usuario {{userName}} | Oficina: Oficina | Subproceso: Nuevo Análisis
										</p>
									</div>
								</div>
							</div>
							<div class="s-traz mb-1">
								<div class="s-traz__check">
									<svg-icon name="check" />
								</div>
								<div>
									<h6 class="m-0">Preparando Análisis</h6>
									<div>
										<div class="d-flex mb-1">
											<span class="s-traz__user mr-2">
												<img src="@/assets/simulator/user_120.png" alt="">
											</span>
											<p class="m-0">{{userName}}</p>
										</div>
										<p class="m-0 small text-muted">
											Etapa: En Preparación | Estado: Preparando Análisis | Código Usuario {{userName}} | Oficina: Oficina | Subproceso: Nuevo Análisis
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="arce">
					<div v-if="arce.status == 'sanction'" class="col-12 px-2 py-2">
						<div class="s-panel">
							
							<div class="s-panel__body ">
								<div class="d-flex align-items-end">
									<div class="">
										<p class="m-0">Order por:</p>
										<div>
											<button class="chater-button">Actividad más reciente</button>
										</div>
									</div>

									<div class="ml-auto">
										<div class="d-flex">
											<div class="chater-button">
												<input placeholder="Buscar en noticias.." type="text">
											</div>
											<button class="chater-button ml-2">
												<SvgIcon name="refresh" />
											</button>
										</div>
										
									</div>
								</div>
								<div>
									<div class="mt-3">
										<div class="d-flex align-items-center">
											<div class="mx-2  s-header__avatar">
												<img src="@/assets/simulator/profile_avatar_96.png" alt="">
											</div>
											<div>
												<span class="text-primary">ALAN DAMIAN MERNO SANDOVAL</span> ha actualizado este registro.
												<p class="m-0">Ahora mismo.</p>
											</div>
										</div>
									</div>

									<div class="border rounded pt-5 pb-2 px-3 mt-3">
										<p class="m-0">Stage</p>
										<p>EN PREPARACIÓN A EN SANCIÓN</p>
									</div>
									<div class="d-flex py-1">
										<span class="d-flex align-items-center py-2 px-3" style="color: #6d6d6d">
											<SvgIcon name="like"/>
											Me gusta
										</span>
										<span class="d-flex align-items-center py-2 px-3" style="color: #6d6d6d">
											<SvgIcon name="share_post"/>
											Comentario
										</span>
									</div>

									<div class="bg-light border py-2 px-3">
										<div class="d-flex">
											<div class="mx-2  s-header__avatar">
												<img src="@/assets/simulator/profile_avatar_96.png" alt="">
											</div>
											<div class="chater-button w-100 overflow-hidden">
												<input class="w-100" type="text" placeholder="Escribir un comentario...">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>